.nova_contact_form{
    min-height: 100vh;
    width: 100%;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nova_contact_form .container_contact{
    width: 70%;
    background: #F2F0F0;
    border-radius: 6px;
    padding: 20px 60px 30px 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  .nova_contact_form .container_contact .content_contact{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nova_contact_form .container_contact .content_contact .left-side{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
  }
  .nova_contact_form .content_contact .left-side::before{
    content: '';
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #afafb6;
  }
  .nova_contact_form .content_contact .left-side .details{
    margin: 14px;
    text-align: center;
  }
  .nova_contact_form .right-side .contact_us_descr{
    color: var(--abbey);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-s);
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .nova_contact_form .content_contact .left-side .details i{
    font-size: 30px;
    color: var(--lightning-yellow);
    margin-bottom: 10px;
  }
  .nova_contact_form .content_contact .left-side .details .topic{
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  .nova_contact_form .content_contact .left-side .details .text-one,
  .nova_contact_form .content_contact .left-side .details .text-two{
    color: var(--abbey);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-s);
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
  }
  .nova_contact_form .container_contact .content_contact .right-side{
    width: 75%;
    margin-left: 75px;
  }
  .nova_contact_form .content_contact .right-side .topic-text{
    color: #F5C313;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .nova_contact_form .right-side .input-box{
    height: 50px;
    width: 100%;
    margin: 12px 0;
  }
  .nova_contact_form .right-side .input-box input,
  .nova_contact_form .right-side .input-box textarea{
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    border-radius: 6px;
    padding: 0 15px;
    resize: none;
  }
  .nova_contact_form .right-side .message-box{
    min-height: 110px;
  }
  .nova_contact_form .right-side .input-box textarea{
    padding-top: 6px;
  }
  .nova_contact_form .right-side .button{
    display: inline-block;
    margin-top: 12px;
  }
  .nova_contact_form .right-side .button input[type="submit"]{
    color: var(--black);
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: var(--lightning-yellow);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .nova_contact_form .button input[type="submit"]:hover{
    background: var(--lightning-yellow);
  }
  .nova_contact_form .success-message{
    text-align: right;
    font-style: italic;
    color: green;
    font-weight: bold;
  }
  @media (max-width: 950px) {
    .nova_contact_form .container_contact{
      width: 90%;
      padding: 30px 40px 40px 35px ;
    }
    .nova_contact_form .container_contact .content_contact .right-side{
     width: 75%;
     margin-left: 55px;
  }
  }
  @media (max-width: 820px) {
    .nova_contact_form .container_contact{
      margin: 40px 0;
      height: 100%;
    }
    .nova_contact_form .container_contact .content_contact{
      flex-direction: column-reverse;
    }
    .nova_contact_form .container_contact .content_contact .left-side{
     width: 100%;
     flex-direction: row;
     margin-top: 40px;
     justify-content: center;
     flex-wrap: wrap;
   }
   .nova_contact_form .container_contact .content_contact .left-side::before{
     display: none;
   }
   .nova_contact_form .container_contact .content_contact .right-side{
     width: 100%;
     margin-left: 0;
   }
   .nova_contact_form .content_contact .right-side .topic-text {
    font-size: 36px;
    line-height: unset;
}
  }
