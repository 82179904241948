@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Poppins:400italic,600italic,700,600,300,500,400|Squada+One:400');
/* The following line is used to measure usage of this code. You can remove it if you want. */
/* @import url('https://px.animaapp.com/6536d4ef16b16fd237f0102e.6536d4f016b16fd237f01031.6536d4f016b16fd237f01033.hcp.png'); */

a {
      text-decoration: none;
      color: var(--black);
}
.d_none {
      display: none;
}
.d_flex{
      display: flex;
}

.container-center-horizontal {
      display: flex;
      flex-direction: row;
      justify-content: center;
      pointer-events: none;
      width: 100%;
}

.container-center-horizontal > * {
      flex-shrink: 0;
      pointer-events: auto;
}

* {
      margin: 0 auto;
      padding: 0;
      box-sizing: border-box;
}
.home_element {
      background-color: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      overflow-x: hidden;
    }
  
    
    .home_element .div {
      background-color: #ffffff;
      height: 100%;
      width: 100%;
    }
    .d_block_mobile{
      display: none;
}

    @media (max-width: 700px) {
      .d_none_mobile{
            display: none;
      }
      .d_block_mobile{
            display: block;
      }
    }

.error_not_found_msg {
      margin-top: 25px;
      margin-bottom: 15px;
      text-align: center;
      color: #000;
      font-family: Poppins;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
  }
  
.error_not_found_msg .warning_text{
      color: brown;
  }
  .error_not_found_msg .num_bold{
      font-weight: 600;
  }