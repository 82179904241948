.home_element .section2_home{
    background: #FFF;
    margin-top: 15px;
}
.home_element .section2_home .section2_home_title{
    color: #000;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 40px */
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.home_element .section2_home .section2_home_description{
    color: #4E4E4E;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    max-width: 550px;
    margin: auto;
}
.home_element .section2_home .section2_home_body{
    display: flex;
    margin: 3% 12%;
}
.home_element .section2_home .section2_home_body .section_home_img_div{
    height: 337px;
    width: 454px;
    margin: 15px
}

.home_element .section2_home .section2_home_body .section_home_img_div .section_home_delivery-pana{
    height: 337px;
    width: 454px;
}
.home_element .section2_home .section2_home_body .section2_home_body_elements{
    padding: 25px;
}
.home_element .section2_home .section2_home_body .section2_home_body_elements .section2_home_group{
    display: flex;
    margin: 20px;
}
.home_element .section2_home .section2_home_body .section2_home_body_elements 
.section2_home_group .section2_home_group_content{
    display:block;
    padding: 7px;
}
.home_element .section2_home .section2_home_body .section2_home_body_elements 
.section2_home_group .section2_hom3_pouces-vers-le-haut-wrapper{
    background-color: #f5c313;
    border-radius: 19px;
    height: 38px;
    min-width: 38px;
    text-align: center;
    padding-top: 8px;
}

.home_element .section2_home .section2_home_body .section2_home_body_elements 
.section2_home_group .section2_hom3_pouces-vers-le-haut-wrapper 
.section2_home_pouces-vers-le-haut{
    height: 20px;
    object-fit: cover;
    width: 20px;
}

.home_element .section2_home .section2_home_body 
.section2_home_body_elements .section2_home_group 
.section2_home_text-wrapper-9{
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 24px */
}

.home_element .section2_home .section2_home_body 
.section2_home_body_elements .section2_home_group 
.section2_home_text-wrapper-10{
    color: #4E4E4E;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 15px;
    max-width: 460px;
}

 /* Responsive code  */
 @media (max-width: 700px) {

    .home_element .section2_home .section2_home_description {
        padding: 5px 15px;
    }

    .home_element .section2_home .section2_home_body {
        display: block;
        margin: 10px;
    }
    .home_element .section2_home .section2_home_body .section_home_img_div {
        height: 300px;
        width: 94vw;
        margin: 15px 4px;
    }
    .home_element .section2_home .section2_home_body .section_home_img_div .section_home_delivery-pana {
        height: 300px;
        width: 92vw;
    }
    .home_element .section2_home .section2_home_body .section2_home_body_elements {
        padding: 8px;
    }
    .home_element .section2_home .section2_home_body .section2_home_body_elements .section2_home_group {
        margin: 10px 20px;
    }
    .home_element .section2_home .section2_home_title {
        font-size: 30px;}

 }