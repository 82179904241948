.home_element .section1_home{
    background: #FFF;
    background-image: url("../../assets/rectangle-4.svg");
}

.home_element .section1_home .section1_home_title{
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 40px;
    white-space: nowrap;
    text-align: center;
    padding: 20px
}
.home_element .section1_home .section1_home_description .section1_home_description_parag{
    color: #252525;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    margin: 5px;
}
/* .home_element .section1_home .section1_home_body{

} */
.home_element .section1_home .section1_home_body .group_section1_home{
    display: flex;
    width: 100vw;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    padding-bottom: 5px;
}
.home_element .section1_home .section1_home_body .group_section1_home .card_colis_section1_home{
    height: 464px;
    width: 337px;
    margin: 20px;
}
.home_element .section1_home .section1_home_body .group_section1_home 
.card_colis_section1_home .overlap-group_section1{
    background-color: #1e1e1e;
    border-radius: 25px;
    height: 100%;
    position: relative;
    width: 100%;
    padding: 18px
}
/* .home_element .section1_home .section1_home_body .group_section1_home 
.card_colis_section1_home .overlap-group_section1 .overlap-group-wrapper_section1{
    height: 50px;
    width: 297px;
} */
.home_element .section1_home .section1_home_body .group_section1_home 
.card_colis_section1_home .overlap-group_section1 .overlap-group-wrapper_section1
.div-wrapper_section1{
    background-color: #ffffff;
    border-radius: 10px;
    padding: 12px;
}
.home_element .section1_home .section1_home_body .group_section1_home 
.card_colis_section1_home .overlap-group_section1 .overlap-group-wrapper_section1
.div-wrapper_section1 .text-wrapper-3_section1{
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
}
.home_element .section1_home .section1_home_body .group_section1_home 
.card_colis_section1_home .overlap-group_section1 .quelques-clics_section1{
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
}
.home_element .section1_home .section1_home_body .group_section1_home 
.card_colis_section1_home .overlap-group_section1 .quelques-clics_section1_error{
    color: #FF0000;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 5px;
}

.home_element .section1_home .section1_home_body .group_section1_home 
.card_colis_section1_home .overlap-group_section1 .section1_search{
    text-align: center;
}

.home_element .section1_home .section1_home_body .group_section1_home 
.card_colis_section1_home .overlap-group_section1 .section1_search .label_section1_search {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    float: left;
}
.home_element .section1_home .section1_home_body .group_section1_home 
.card_colis_section1_home .overlap-group_section1 .group-2_section1{
    margin-top: 24%;
}

.home_element .section1_home .section1_home_body .group_section1_home 
.card_colis_section1_home .overlap-group_section1 .group-2_section1 .section1_search .section1_search_input{
    border: 0.5px solid;
    border-color: #ffffff;
    border-radius: 10px;
    height: 50px;
    width: 295px;
    background-color: #1e1e1e;
    margin-top: 5px;
    padding: 10px 25px;

    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
}

.home_element .section1_home .section1_home_body .group_section1_home 
.card_colis_section1_home .overlap-group_section1 .group-2_section1 .section1_search .section1_search_input:focus{
    outline: none;
}

.home_element .section1_home .section1_home_body .group_section1_home 
.card_colis_section1_home .overlap-group_section1 .group-2_section1 .section1_search .section1_submit_btn{
    align-items: center;
    background-color: var(--lightning-yellow);
    border-radius: 10px;
    border: 0;
    padding: 15px 40px;
    margin-top: 40px;
    /* transform: translateX(70%); */
}
.title_group_card{
    display: none;
}

@media (max-width: 700px) {

    .title_group_card{
        display: block;
        font-weight: bold;
    }
    .home_element .section1_home .section1_home_body .group_section1_home{
        display: block;
    }
    .home_element .section1_home .section1_home_body .group_section1_home {
        padding-left: 2%;
        padding-right: 2%;
    }
    .home_element .section1_home .section1_home_title {
        font-size: 32px;
        line-height: 40px;
        white-space: unset;
        padding: 20px 5px;
        margin-left: 5px;
        margin-right: 5px;
    }
    .home_element .section1_home .section1_home_body .group_section1_home 
    .card_colis_section1_home .overlap-group_section1 .section1_search{
        display: none;
    }
    .home_element .section1_home .section1_home_description .section1_home_description_parag {
        display: none;
    }
    .home_element .section1_home .section1_home_body .group_section1_home .card_colis_section1_home {
        height: 232px;
        margin: 15px 10px;
      }

      .home_element .section1_home {
        background: #FFF;
        background-color: var(--lightning-yellow);
        background-image: unset;
        padding-left: 5px;
        padding-right: 5px;
    }

    .home_element .section1_home .section1_home_body .group_section1_home .card_colis_section1_home{
        margin: 15px;
    }
}

@media (max-width: 430px){
    .home_element .section1_home .section1_home_body .group_section1_home {
        padding-left: 6vw;
        padding-right: 6vw;
    }
}

@media (max-width: 375px){
.home_element .section1_home .section1_home_body .group_section1_home {
    padding-left: 0.7vw;
    padding-right: 0.7vw;
}
}
@media (max-width: 414px){
    .home_element .section1_home .section1_home_body .group_section1_home {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}
@media (max-width: 400px){
    .home_element .section1_home .section1_home_body .group_section1_home {
        padding-left: 2vw;
        padding-right: 2vw;
    }
    }
@media (max-width: 360px) {

    .home_element .section1_home .section1_home_body .group_section1_home {
        padding-left: 1vw;
        padding-right: 1vw;
    }

    .home_element .section1_home .section1_home_title {
        font-size: 30px;}

    .home_element .section1_home .section1_home_body .group_section1_home .card_colis_section1_home {
        margin: 15px 5px;
    }

    }