.suivi_colis_body{
    margin-left: auto;
    margin-right: auto;
    background: #FFF;
    margin-bottom: 10%;
}

.suivi_colis_body .suivi_colis_title{
    margin-top: 5%;
}

.suivi_colis_body .vector{
    margin-bottom: 4%;
}

.suivi_colis_body .vector_img{
    transform: rotate(-0.174deg);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.suivi_colis_body .suivi_colis_title .text-wrapper{
    color: #F5C313;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.suivi_colis_body .wrap_suivi_colis{
    width: fit-content;
    height: auto;
    flex-shrink: 0;
    border-radius: 35px;
    background: #F2F0F0;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
    margin-left: auto;
    margin-right: auto;

}

.suivi_colis_body .wrap_suivi_colis .suivi_colis_info{
    color: #000;
    font-family: Poppins;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    display: flex;
    padding-top: 3%;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 22px;

}

.suivi_colis_body .wrap_suivi_colis .suivi_colis_info .n-de-suivi{
    text-align: right;
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.suivi_colis_body .wrap_suivi_colis .suivi_colis_info .n-de-suivi-btnstyle{
    width: 350px;
    height: 70px;
    border-radius: 10px;
    background: #F5C313;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.10);

    text-align: center;
    font-size: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.suivi_colis_body .item_style_suivi{
    /* width: fit-content; */
    height: auto;

    width: 816px;
    margin-left: auto;
    margin-right: auto;

    border-radius: 35px;
    background: linear-gradient(88deg, #414141 2.47%, rgba(65, 65, 65, 0.00) 199.63%);
    padding: 21px 21px;
}
.suivi_colis_body .item_style_suivi .item_class_suivi_unit{
    width: 316px;
    height: 398px;
    border-radius: 10px;
    background: #FFF;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 38px;
}

.suivi_colis_body .item_style_suivi .nova_logo_img{
    margin-top: -15%;
    transform: translateY(38.5%) translateX(-25%);
}

.StepProgress {
    position: relative;
    padding-left: 45px;
  }

.StepProgress::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
    border-left: 2px solid #CCC;
  }

.StepProgress .StepProgress-item.current::before {
      border-left: 2px solid green;
    }
    
.StepProgress .StepProgress-item.current::after {
      content: counter(list);
      padding-top: 1px;
      width: 19px;
      height: 18px;
      top: -4px;
      left: -40px;
      font-size: 14px;
      text-align: center;
      color: green;
      border: 2px solid green;
      background-color: white;
  }

 .StepProgress .StepProgress-item.is-done::before {
      border-left: 2px solid green;
  }

 .StepProgress .StepProgress-item.is-done::after {
      content: "✔";
      font-size: 10px;
      color: #FFF;
      text-align: center;
      border: 2px solid green;
      background-color: green;
  }


  .StepProgress .StepProgress-item{
    position: relative;
    counter-increment: list;
  }
  .StepProgress .StepProgress-item::before{
    display: inline-block;
        content: '';
        position: absolute;
        left: -30px;
        height: 100%;
        width: 10px;
  }
  .StepProgress .StepProgress-item::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: -37px;
    width: 12px;
    height: 12px;
    border: 2px solid #CCC;
    border-radius: 50%;
    background-color: #FFF;
  }
  .StepProgress .StepProgress-item:not(:last-child) {
      padding-bottom: 30px;
  }
  .StepProgress .StepProgress-item strong{
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-m);
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0;
    min-height: 21px;
  }

@media (max-width: 700px) {
    .suivi_colis_body .suivi_colis_title .text-wrapper{
        font-size: 32px;
    }
    .suivi_colis_body .vector{
        margin-bottom: 8%;
    }
    .suivi_colis_body .wrap_suivi_colis .suivi_colis_info{
        padding-top: 5%;
        margin-left: 8%;
        margin-right: 9%;
    }

    .suivi_colis_body .item_style_suivi .nova_logo_img{
        margin-top: -40%;
    }


}
@media (max-width: 816px) {
    .suivi_colis_body .item_style_suivi{
        width: fit-content;
    }
}