/* .nova_empty_page {
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
    display: flex;
    align-items: center;  
    min-height: 30vh;
    background-color: #FFF;
  }
  
.nova_empty_page .card-container {
    perspective: 50em;
  }
.nova_empty_page .card-container:nth-child(1) { --bi: repeating-linear-gradient(30deg, #111 0 0.25em, #333 0 1em); }
.nova_empty_page .card-container:nth-child(2) { --bi: linear-gradient(#555 5em, #0000 3em), linear-gradient(60deg, #880E4F, #1A237E); } 
  
.nova_empty_page .card {
    position: relative;
    width: 80vw;
    padding: 3em;
    color: #fff;
    transform: rotateY(0deg) rotateX(15deg);
    transform-style: preserve-3d;
    transition: transform 1s;
  }
  .nova_empty_page .card:hover {
    transform: rotateY(-30deg) rotateX(-15deg);
  }
  
.nova_empty_page .layers {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    transform-style: preserve-3d;
    z-index: -1;
  }
  
.nova_empty_page .layer {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    border-radius: 1em;
    background-image: var(--bi);
    transform: translateZ(var(--tz));
    box-shadow: 0 0 0.5em #000d inset;
    
    @for $i from 0 to 10 {
      &:nth-child(#{$i + 1}) {
        --tz: #{$i * -4}px;
      }
    }
    
  }
  .nova_empty_page .layer:last-child {
    box-shadow: 0 0 0.5em #000d inset, 0 0 5px #000;
  }
  @media (min-width: 900px) {
    .nova_empty_page {margin-left: 10%;}
  }

  @media (max-width: 700px) {

    .nova_empty_page {
        box-sizing: border-box;
        font-family: Poppins, sans-serif;
        display: flex;
        align-items: center;  
        min-height: 30vh;
        background-color: #FFF;
      }

    .nova_empty_page .card {
        width: 350px;
        padding: 2em;
        margin: 5px 16px;
        transition: transform 1s;
    }
  } */

  .cours__section__form {
    margin: auto;
    max-width: 500px;
    position: relative; }
  
  .cours__section__search {
    position: relative;
    width: 100%;
    display: flex;
    overflow: hidden;
    border-radius: 50px;
    margin: 0;
    padding: 0; }
    .cours__section__search .cours__section__search__btn {
      width: 20%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #F5C313;
      color: var(--black);
      font-family: var(--font-family-poppins);
      font-size: var(--font-size-m);
      font-weight: 500;
      border: 0;
      cursor: pointer;
      outline: none; }
    .cours__section__search input[type=text] {
      -webkit-border-radius: 0 !important;
      border-radius: 50px 0px 0px 50px !important;
      margin: 0;
      width: 80%;
      outline: none;
      padding: 10px 10px;
      display: inline-block;
      font-weight: 500;
      font-size: 17px;
      padding-left: 25px !important; }

@media (max-width: 700px) {

  .cours__section__form {
      margin: 20px 15px;
  }

}
