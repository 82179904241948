.Navbar {
      height: 95px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--white-2);
}

.Navbar .rectangle-1 {
      background-color: var(--lightning-yellow);
      height: 2px;
      left: 0;
      position: absolute;
      top: 95px;
      width: 100%;
}

.Navbar > .nav-logo {
      align-items: flex-start;
      cursor: pointer;
      display: flex;
      height: 107px;
      left: 74px;
      min-width: 165px;
      top: 0;
      font-weight: 700;
      font-size: 21px;
      margin: 15px;
      color: var(--black);
}

.Navbar .overlap-group1 {
      height: 107px;
      position: relative;
      width: 161px;
}

.Navbar .novazon-container {
      height: 107px;
      left: 0;
      position: absolute;
      top: 0;
      width: 161px;
}

.Navbar .logo-novazon-icone-copy-1 {
      height: 107px;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 97px;
}

.Navbar .novazon {
      color: var(--black);
      font-family: var(--font-family-squada_one);
      font-size: 25px;
      font-weight: 400;
      left: 79px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      top: 27px;
      white-space: nowrap;
}

.Navbar .relay {
      color: var(--black);
      font-family: var(--font-family-klima-bolditalic);
      font-size: var(--font-size-xxs);
      font-style: italic;
      font-weight: 700;
      left: 105px;
      letter-spacing: 0;
      line-height: normal;
      position: absolute;
      text-align: center;
      top: 53px;
      white-space: nowrap;
}

.Navbar > .nav-items > a {
      color: #4e4e4e;
      font-size: 16px;
      text-decoration: none;
      margin: 15px;
      position: relative;

      font-family: var(--font-family-poppins);
      font-size: var(--font-size-m);
      font-weight: 600;
      letter-spacing: 0;
      line-height: normal;
      min-height: 18px;
      min-width: 109px;
}

.Navbar > .nav-items > a:hover {
      opacity: 1;
}

.Navbar > .nav-items > a::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 2px;
      background: var(--lightning-yellow);
      transition: all 0.45s;
}

.Navbar > .nav-items > a:hover::before {
      width: 100%;
}

.Navbar .frame-1 {
      display: flex;
      margin-right: 30px;
}

.Navbar .locate {
      display: flex;
      margin-right: 30px;
      align-items: center;
}

.Navbar .gisearth {
      height: 19px;
      top: 39px;
      width: 19px;
}

.Navbar .togo {
      color: var(--black);
      font-family: var(--font-family-poppins);
      font-size: var(--font-size-xs);
      font-weight: 600;
      letter-spacing: 0;
      line-height: normal;
      top: 42px;
}
.Navbar .frame-1 .btn_contact {
      display: inline-flex;
}

.Navbar .btn_contact {
      border: none;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;

      align-items: center;
      background-color: var(--lightning-yellow);
      border-radius: 30px;
      flex: 0 0 auto;
      gap: 10px;
      justify-content: center;
      overflow: hidden;
      padding: 15px 40px;

      color: var(--black);
      font-family: var(--font-family-poppins);
      font-size: var(--font-size-m);
      font-weight: 500;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      text-align: center;
      width: fit-content;
}
.Navbar > .nav-items .btn_contact {
      display: none;   
}

.Navbar > .nav-toggle {
      display: none;
}

.active_nav::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 8px;
      background:  linear-gradient(90deg, #F5C313 70.83%, rgba(245, 195, 19, 0.00) 100%);
      bottom: -37px;
      left: 0;
  }

  #lvgrp_nav2, #suivi_nav2, #relais_nav2, #aide_nav2, #home_nav{
      display: none;
  }

@media (max-width: 700px) {

      .active_nav::after {
            width: 25%;
            bottom: -10px;
        }

      .Navbar > .nav-items {
            position: absolute;
            top: 97px;
            display: flex;
            flex-direction: column;
            background: var(--white-2);
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            transition: all 0.45s;
      }
      .Navbar > .nav-items .btn_contact {
            display: flex;   
      }
      .Navbar > .frame-1 .btn_contact {
            display: none;
      }
      .Navbar .locate {
            margin-right: 0px;
            margin-left: 30px;
      }

      .Navbar > .nav-items > a::before {
            background: transparent;
      }

      .Navbar > .nav-items.open {
            z-index: 10001;
            transform: translateX(0);
      }

      .Navbar > .nav-toggle {
            display: flex;
            width: 50px;
            height: 50px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
      }

      .nav-toggle > .bar {
            position: relative;
            width: 32px;
            height: 2px;
            background: var(--black);
            transition: all 0.45s ease-in-out;
      }

      .nav-toggle > .bar::before,
      .nav-toggle > .bar::after {
            content: '';
            position: absolute;
            height: 2px;
            background: var(--black);
            border-radius: 2px;
            transition: all 0.45s ease-in-out;
      }

      .nav-toggle > .bar::before {
            width: 25px;
            transform: translateY(-8px);
            right: 0;
      }

      .nav-toggle > .bar::after {
            width: 32px;
            transform: translateY(8px);
      }

      .nav-toggle.open > .bar {
            transform: translateX(-40px);
            background: transparent;
      }

      .nav-toggle.open > .bar::before {
            width: 32px;
            transform: rotate(45deg) translate(26px, -26px);
      }

      .nav-toggle.open > .bar::after {
            transform: rotate(-45deg) translate(26px, 26px);
      }
}
