.faq .faq-list {
    padding: 0 100px;
  }
  
  .faq .faq-list ul {
    padding: 0;
    list-style: none;
  }
  
  .faq .faq-list li + li {
    margin-top: 15px;
  }
  
  .faq .faq-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
  }
  
  .faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    outline: none;
    cursor: pointer;
    color: var(--black);
  }
  
  /* .faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: #47b2e4;
  }
  
  .faq .faq-list .icon-show,
  .faq .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  } */
  
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  
  /* .faq .faq-list .icon-show {
    display: none;
  }
  
  .faq .faq-list a.collapsed {
    color: #37517e;
    transition: 0.3s;
  }
  
  .faq .faq-list a.collapsed:hover {
    color: #47b2e4;
  }
  
  .faq .faq-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .faq .faq-list a.collapsed .icon-close {
    display: none;
  } */
  .faq_contact_us{
    text-decoration: underline;
    font-size: 18px;
  }
  
  @media (max-width: 1200px) {
    .faq .faq-list {
      padding: 0;
    }
  }
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background: #F2F0F0;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: var(--lightning-yellow);
  }
  
  .section-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  
  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: var(--lightning-yellow);
    bottom: 0;
    left: calc(50% - 20px);
  }
  
  .section-title p {
    margin-bottom: 0;
  }

  /*  */
  .collapsible {
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    font-size: 15px;
  }
  
  .active_faq, .collapsible:hover {
    background: linear-gradient(88deg, #414141 2.47%, rgba(65, 65, 65, 0.00) 199.63%);
  }
  
  .content {
    display: none;
    overflow: hidden;
  }

  .collapsible:after {
    content: '\002B';
    color: var(--black);
    font-weight: bold;
    float: right;
    margin-left: 5px;
  }
  
  .active_faq:after {
    content: "\2212";
  }
  
  .faq_desc{
    font-size: 17px;
  }