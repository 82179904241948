body {
  margin: 0;
  background: #ffffff;
  height: 100vh;
  width: 100vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root { 
  --black: #000000;
  --fun-green: #006130;
  --island-spice: #fffcef;
  --lightning-yellow: #f5c313;
  --white: #ffffff99;
  --white-2: #ffffff;
 
  --font-size-l: 20px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xl: 24px;
  --font-size-xs: 12px;
  --font-size-xxl: 36px;
  --font-size-xxs: 10px;
 
  --font-family-klima-bolditalic: "Klima-BoldItalic", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
  --font-family-squada_one: "Squada One", Helvetica;
}
.poppins-medium-white-12px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
}
.poppins-medium-white-10px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 500;
}

.poppins-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.poppins-bold-black-16px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.poppins-bold-black-20px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.poppins-bold-lightning-yellow-24px {
  color: var(--lightning-yellow);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.poppins-normal-fun-green-16px {
  color: var(--fun-green);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.poppins-bold-white-16px {
  color: var(--white-2);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}
