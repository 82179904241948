.loader_nova{
    background: #FFF;
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: auto;
    /* position: absolute;
    top: 50%;
    left: 50%; */
    transform: translate(-50%,-50%);
    font-family: helvetica, arial, sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    color: var(--lightning-yellow);
    letter-spacing: 0.2em;
    
   
  }
  .loader_nova::before, .loader_nova::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: var(--lightning-yellow);
    position: absolute;
    animation: load .7s infinite alternate ease-in-out;
  }
  
  .loader_nova::before {
    top: 0;
  }
  
  .loader_nova::after {
    bottom: 0;
  }
  
  @keyframes load {
    0% { left: 0; height: 30px; width: 15px }
    50% { height: 8px; width: 40px }
    100% { left: 235px; height: 30px; width: 15px}
  }