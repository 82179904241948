/* Body css valid */

  
  /* End body css valid */
  .home_element .overlap {
    height: 1327px;
    left: 0;
    position: absolute;
    top: 99px;
    width: 100%;
  }
  
  .home_element .ellipse {
    background-color: #f5c313a3;
    border-radius: 380.5px/96px;
    filter: blur(400px);
    height: 192px;
    left: 599px;
    position: absolute;
    top: 243px;
    transform: rotate(-18.44deg);
    width: 761px;
  }
  
  .home_element .rectangle {
    height: 50%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .home_element .img {
    height: 663px;
    left: 0;
    position: absolute;
    top: 663px;
    width: 100%;
  }
  
  .home_element .de-l-envoi-la-r {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 700;
    left: 185px;
    letter-spacing: 0;
    line-height: 40px;
    position: absolute;
    top: 216px;
  }
  
  .home_element .lorem-ipsum-dolor {
    color: #4d4d4d;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 300;
    left: 185px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 316px;
  }
  
  .home_element .ellipse-2 {
    border: 1px solid;
    border-color: #f5c313;
    border-radius: 20px;
    height: 40px;
    left: 185px;
    position: absolute;
    top: 391px;
    width: 40px;
  }
  
  .home_element .polygon {
    height: 12px;
    left: 202px;
    position: absolute;
    top: 405px;
    width: 10px;
  }
  
  .home_element .text-wrapper {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 500;
    left: 233px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 402px;
    width: 57px;
  }
  
  .home_element .text-wrapper-2 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 700;
    left: 365px;
    letter-spacing: 0;
    line-height: 40px;
    position: absolute;
    top: 727px;
    white-space: nowrap;
  }
  
  .home_element .p {
    color: #252525;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 300;
    left: 446px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 787px;
  }
  
  .home_element .LOGO-NOVAZON-icone {
    height: 500px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 305px;
    width: 246px;
  }
  
  .home_element .group {
    height: 465px;
    left: 187px;
    position: absolute;
    top: 862px;
    width: 1065px;
  }
  
  .home_element .envoyer-colis {
    height: 464px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 337px;
  }
  
  .home_element .overlap-group {
    background-color: #1e1e1e;
    border-radius: 25px;
    height: 464px;
    position: relative;
    width: 335px;
  }
  
  .home_element .overlap-group-wrapper {
    height: 50px;
    left: 20px;
    position: absolute;
    top: 20px;
    width: 297px;
  }
  
  .home_element .div-wrapper {
    background-color: #ffffff;
    border-radius: 10px;
    height: 50px;
    position: relative;
    width: 295px;
  }
  
  .home_element .text-wrapper-3 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 600;
    left: 31px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 13px;
  }
  
  .home_element .group-2 {
    height: 77px;
    left: 20px;
    position: absolute;
    top: 221px;
    width: 299px;
  }
  
  .home_element .overlap-2 {
    border-radius: 10px;
    height: 50px;
    left: 0;
    position: absolute;
    top: 27px;
    width: 295px;
  }
  
  .home_element .text-wrapper-4 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    left: 31px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 15px;
  }
  
  .home_element .rectangle-2 {
    border: 0.5px solid;
    border-color: #ffffff;
    border-radius: 10px;
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 295px;
  }
  
  .home_element .text-wrapper-5 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
  }
  
  .home_element .quelques-clics {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 29px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 85px;
  }
  
  .home_element .frame-1 {
    left: 106px !important;
    position: absolute !important;
    top: 338px !important;
  }
  
  .home_element .suivre-coli {
    height: 464px;
    left: 365px;
    position: absolute;
    top: 0;
    width: 337px;
  }
  
  .home_element .text-wrapper-6 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 600;
    left: 89px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 13px;
  }
  
  .home_element .group-3 {
    height: 77px;
    left: 20px;
    position: absolute;
    top: 222px;
    width: 299px;
  }
  
  .home_element .polygon-2 {
    height: 8px;
    left: 255px;
    position: absolute;
    top: 23px;
    width: 10px;
  }
  
  .home_element .votre-colis-a-de-la {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 24px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 85px;
  }
  
  .home_element .frame-instance {
    left: 106px !important;
    position: absolute !important;
    top: 339px !important;
  }
  
  .home_element .relai {
    height: 464px;
    left: 730px;
    position: absolute;
    top: 0;
    width: 337px;
  }
  
  .home_element .text-wrapper-7 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 600;
    left: 60px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 13px;
  }
  
  .home_element .avec-pr-s-de {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 36px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 85px;
  }
  
  .home_element .frame-1-instance {
    left: 94px !important;
    position: absolute !important;
    top: 338px !important;
  }
  
  .home_element .delivery-address {
    height: 526px;
    left: 656px;
    position: absolute;
    top: 28px;
    width: 684px;
  }
  
  .home_element .text-wrapper-8 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 700;
    left: 383px;
    letter-spacing: 0;
    line-height: 40px;
    position: absolute;
    top: 1631px;
    white-space: nowrap;
  }
  
  .home_element .lorem-ipsum-dolor-2 {
    color: #4d4d4d;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 300;
    left: 446px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 1691px;
  }
  
  .home_element .group-4 {
    height: 85px;
    left: 815px;
    position: absolute;
    top: 1829px;
    width: 507px;
  }
  
  .home_element .pouces-vers-le-haut-wrapper {
    background-color: #f5c313;
    border-radius: 19px;
    height: 38px;
    left: 0;
    position: absolute;
    top: 0;
    width: 38px;
  }
  
  .home_element .pouces-vers-le-haut {
    height: 20px;
    left: 9px;
    object-fit: cover;
    position: absolute;
    top: 9px;
    width: 20px;
  }
  
  .home_element .text-wrapper-9 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 45px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 9px;
    white-space: nowrap;
  }
  
  .home_element .text-wrapper-10 {
    color: #4d4d4d;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 45px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 43px;
  }
  
  .home_element .group-5 {
    height: 64px;
    left: 815px;
    position: absolute;
    top: 1934px;
    width: 404px;
  }
  
  .home_element .group-6 {
    height: 85px;
    left: 815px;
    position: absolute;
    top: 2018px;
    width: 521px;
  }
  
  .home_element .pouces-vers-le-haut-2 {
    height: 22px;
    left: 8px;
    object-fit: cover;
    position: absolute;
    top: 8px;
    width: 22px;
  }
  
  .home_element .overlap-3 {
    height: 910px;
    left: 0;
    position: absolute;
    top: 2281px;
    width: 100%;
  }
  
  .home_element .rectangle-3 {
    background-color: #f5c313;
    height: 758px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .home_element .rectangle-4 {
    height: 586px;
    left: 695px;
    position: absolute;
    top: 90px;
    width: 660px;
  }
  
  .home_element .LOGO-NOVAZON-icone-2 {
    height: 529px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 381px;
    width: 365px;
  }
  
  .home_element .group-7 {
    height: 357px;
    left: 100px;
    position: absolute;
    top: 200px;
    width: 616px;
  }
  
  .home_element .achetez-et-fa-tes {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 40px;
    position: absolute;
    top: 0;
    width: 610px;
  }
  
  .home_element .text-wrapper-11 {
    color: #252525;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 300;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 146px;
    width: 334px;
  }
  
  .home_element .logos-google-play-wrapper {
    background-color: #000000;
    border-radius: 10px;
    height: 70px;
    left: 0;
    position: absolute;
    top: 286px;
    width: 190px;
  }
  
  .home_element .logos-google-play {
    height: 40px;
    left: 8px;
    position: absolute;
    top: 15px;
    width: 174px;
  }
  
  .home_element .overlap-4 {
    height: 72px;
    left: 210px;
    position: absolute;
    top: 285px;
    width: 190px;
  }
  
  .home_element .rectangle-5 {
    background-color: #000000;
    border-radius: 10px;
    height: 70px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 190px;
  }
  
  .home_element .logos-apple-app {
    height: 40px;
    left: 10px;
    position: absolute;
    top: 16px;
    width: 40px;
  }
  
  .home_element .text-wrapper-12 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 25px;
    font-weight: 500;
    left: 56px;
    letter-spacing: 0;
    line-height: 72px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .home_element .ce-qu-ils-disent-wrapper {
    background-color: #f5c313;
    border-radius: 0px 54.5px 54.5px 0px;
    height: 109px;
    left: 0;
    position: absolute;
    top: 3191px;
    width: 481px;
  }
  
  .home_element .ce-qu-ils-disent {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 700;
    left: 100px;
    letter-spacing: 0;
    line-height: 73.8px;
    position: absolute;
    top: 17px;
    white-space: nowrap;
  }
  
  .home_element .j-adore-la-simplicit {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 45px;
    font-weight: 600;
    left: 132px;
    letter-spacing: 0;
    line-height: 60.5px;
    position: absolute;
    top: 3341px;
  }
  
  .home_element .ellipse-3 {
    height: 80px;
    left: 126px;
    position: absolute;
    top: 3573px;
    width: 80px;
  }
  
  .home_element .text-wrapper-13 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 226px;
    letter-spacing: 0;
    line-height: 26.9px;
    position: absolute;
    top: 3587px;
    white-space: nowrap;
  }
  
  .home_element .text-wrapper-14 {
    color: #7b7b7b;
    font-family: "Poppins", Helvetica;
    font-size: 15px;
    font-weight: 500;
    left: 226px;
    letter-spacing: 0;
    line-height: 20.2px;
    position: absolute;
    top: 3616px;
    white-space: nowrap;
  }
  
  .home_element .bxs-quote-alt-left {
    height: 95px;
    left: 1242px;
    position: absolute;
    top: 3209px;
    width: 95px;
  }
  
  .home_element .arrow {
    height: 22px;
    left: 1285px;
    position: absolute;
    top: 3603px;
    width: 26px;
  }
  
  .home_element .arrow-2 {
    height: 22px;
    left: 1206px;
    position: absolute;
    top: 3603px;
    width: 26px;
  }
  
  .home_element .overlap-5 {
    background-color: #1a1a1a;
    height: 682px;
    left: 0;
    position: absolute;
    top: 3798px;
    width: 100%;
  }
  
  .home_element .plus-de-pouvoir-pour {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 46.6px;
    width: 50%;
    margin: 8%;
  }
  
  .home_element .overlap-6 {
    background-color: #ffffff;
    border-radius: 31.76px;
    height: 62px;
    left: 890px;
    position: absolute;
    top: 124px;
    width: 211px;
  }
  
  .home_element .text-wrapper-15 {
    color: #1b1b1b;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 28px;
    letter-spacing: 0;
    line-height: 31.3px;
    position: absolute;
    text-align: center;
    top: 14px;
    white-space: nowrap;
    width: 153px;
  }
  
  .home_element .overlap-7 {
    background-color: #ffffff2e;
    border-radius: 31.76px;
    height: 62px;
    left: 1123px;
    position: absolute;
    top: 124px;
    width: 211px;
  }
  
  .home_element .text-wrapper-16 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 33px;
    letter-spacing: 0;
    line-height: 31.3px;
    position: absolute;
    text-align: center;
    top: 14px;
    white-space: nowrap;
    width: 144px;
  }
  
  .home_element .line {
    height: 1px;
    left: 118px;
    object-fit: cover;
    position: absolute;
    top: 274px;
    width: 1216px;
  }
  
  .home_element .text-wrapper-17 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 700;
    left: 466px;
    letter-spacing: 0;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: 365px;
    white-space: nowrap;
    width: 103px;
  }
  
  .home_element .text-wrapper-18 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 700;
    left: 692px;
    letter-spacing: 0;
    line-height: 25px;
    position: absolute;
    top: 365px;
    white-space: nowrap;
    width: 103px;
  }
  
  .home_element .text-wrapper-19 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 700;
    left: 910px;
    letter-spacing: 0;
    line-height: 25px;
    position: absolute;
    top: 365px;
    white-space: nowrap;
    width: 103px;
  }
  
  .home_element .text-wrapper-20 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 700;
    left: 1129px;
    letter-spacing: 0;
    line-height: 25px;
    position: absolute;
    top: 365px;
    white-space: nowrap;
    width: 103px;
  }
  
  .home_element .text-wrapper-21 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 476px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 407px;
    width: 149px;
  }
  
  .home_element .text-wrapper-22 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 692px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 407px;
    width: 149px;
  }
  
  .home_element .text-wrapper-23 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 910px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 407px;
    width: 149px;
  }
  
  .home_element .text-wrapper-24 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 1129px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 407px;
    width: 149px;
  }
  
  .home_element .text-wrapper-25 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 476px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 440px;
    width: 149px;
  }
  
  .home_element .text-wrapper-26 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 692px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 440px;
    width: 149px;
  }
  
  .home_element .text-wrapper-27 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 910px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 440px;
    width: 149px;
  }
  
  .home_element .text-wrapper-28 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 1129px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 440px;
    width: 149px;
  }
  
  .home_element .text-wrapper-29 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 476px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 474px;
    width: 149px;
  }
  
  .home_element .text-wrapper-30 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 692px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 474px;
    width: 149px;
  }
  
  .home_element .text-wrapper-31 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 910px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 474px;
    width: 149px;
  }
  
  .home_element .text-wrapper-32 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 1129px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 474px;
    width: 149px;
  }
  
  .home_element .text-wrapper-33 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 476px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 507px;
    width: 149px;
  }
  
  .home_element .text-wrapper-34 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 692px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 507px;
    width: 149px;
  }
  
  .home_element .text-wrapper-35 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 910px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 507px;
    width: 149px;
  }
  
  .home_element .text-wrapper-36 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 1129px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 507px;
    width: 149px;
  }
  
  .home_element .text-wrapper-37 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 476px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 540px;
    width: 149px;
  }
  
  .home_element .text-wrapper-38 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 692px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 540px;
    width: 149px;
  }
  
  .home_element .text-wrapper-39 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 910px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 540px;
    width: 149px;
  }
  
  .home_element .text-wrapper-40 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 1129px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 540px;
    width: 149px;
  }
  
  .home_element .text-wrapper-41 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 476px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 573px;
    width: 149px;
  }
  
  .home_element .text-wrapper-42 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 500;
    left: 692px;
    letter-spacing: 0;
    line-height: 15.7px;
    position: absolute;
    top: 573px;
    width: 149px;
  }
  
  .home_element .text-wrapper-43 {
    color: #ffffff99;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 118px;
    letter-spacing: 0;
    line-height: 26.1px;
    position: absolute;
    top: 405px;
    width: 237px;
  }
  
  .home_element .bxl-facebook {
    height: 35px;
    left: 106px;
    position: absolute;
    top: 529px;
    width: 35px;
  }
  
  .home_element .ant-design-twitter {
    height: 30px;
    left: 152px;
    position: absolute;
    top: 532px;
    width: 31px;
  }
  
  .home_element .ant-design-instagram {
    height: 35px;
    left: 193px;
    position: absolute;
    top: 529px;
    width: 35px;
  }
  
  .home_element .text-wrapper-44 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 30px;
    font-weight: 600;
    left: 117px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 354px;
  }
  
  .home_element .delivery-pana {
    height: 337px;
    left: 185px;
    position: absolute;
    top: 1798px;
    width: 454px;
  }
  
  .home_element .overlap-wrapper {
    height: 107px;
    left: 0;
    position: fixed;
    top: 0;
    width: 1442px;
  }
  
  .home_element .overlap-8 {
    height: 107px;
    position: relative;
    width: 100%;
  }
  
  .home_element .rectangle-6 {
    background-color: #f5c313;
    height: 2px;
    left: 0;
    position: absolute;
    top: 95px;
    width: 100%;
  }
  
  .home_element .rectangle-7 {
    background-color: #ffffff;
    height: 95px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .home_element .LOGO {
    height: 107px;
    left: 74px;
    position: absolute;
    top: 0;
    width: 165px;
  }
  
  .home_element .overlap-9 {
    height: 107px;
    position: relative;
    width: 161px;
  }
  
  .home_element .overlap-group-2 {
    height: 107px;
    left: 0;
    position: absolute;
    top: 0;
    width: 161px;
  }
  
  .home_element .LOGO-NOVAZON-icone-3 {
    height: 107px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 97px;
  }
  
  .home_element .novazon {
    color: #000000;
    font-family: "Squada One", Helvetica;
    font-size: 25px;
    font-weight: 400;
    left: 79px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 27px;
    white-space: nowrap;
  }
  
  .home_element .relay {
    color: #000000;
    font-family: "Klima-BoldItalic", Helvetica;
    font-size: 10px;
    font-style: italic;
    font-weight: 700;
    left: 105px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 53px;
    white-space: nowrap;
  }
  
  .home_element .navbar {
    height: 18px;
    left: 518px;
    position: absolute;
    top: 39px;
    width: 411px;
  }
  
  .home_element .text-wrapper-45 {
    color: #4d4d4d;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 600;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
  }
  
  .home_element .text-wrapper-46 {
    color: #4d4d4d;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 600;
    left: 159px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
  }
  
  .home_element .text-wrapper-47 {
    color: #4d4d4d;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 600;
    left: 288px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
  }
  
  .home_element .text-wrapper-48 {
    color: #4d4d4d;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 600;
    left: 375px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
  }
  
  .home_element .gis-earth {
    height: 19px;
    left: 1067px;
    position: absolute;
    top: 39px;
    width: 19px;
  }
  
  .home_element .text-wrapper-49 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 10px;
    font-weight: 600;
    left: 1089px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 42px;
  }
  
  .home_element .design-component-instance-node {
    font-weight: 500 !important;
  }
  
  .home_element .frame-2 {
    border-radius: 30px !important;
    flex: 0 0 auto !important;
  }
  
  .home_element .frame-3 {
    left: 1175px !important;
    position: absolute !important;
    top: 25px !important;
  }
  .btn_card_link_mobile, .d_flex_mobile {
    display: none;
  }
  /* Resposive code  */
  @media (max-width: 700px) {

    .home_element .plus-de-pouvoir-pour {
      font-size: 33px;
      font-weight: 600;
      line-height: 44px;
    }

    .card_search_img{
      width: 80px;
      height: 80px;
      border-radius: 10%;
}
  .btn_card_link_mobile {
    display: block;
  }
  .d_flex_mobile{
    display: flex;
  }
  .btn_card_link_mobile{
    color: var(--black);
    background-color: var(--lightning-yellow);
    border-radius: 10px;
    height: 55px;
    font-size: 27px;
    padding: 15px;
    margin: 15px 15px 15px 60px;
    width: fit-content;
  }
  .btn_card_link_mobile_1{
    margin: 15px 15px 15px 80px;
  }

}