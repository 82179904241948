.relais_body{
    margin-left: auto;
    margin-right: auto;
    background: #FFF;
    margin-bottom: 10%;
}

.relais_body .relais_title{
    margin-top: 5%;
}

.relais_body .vector{
    margin-bottom: 4%;
}

.relais_body .vector_img{
    transform: rotate(-0.174deg);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.relais_body .relais_title .text-wrapper{
    color: #F5C313;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.relais_body .wrap_relais_colis{
    width: fit-content;
    height: auto;
    flex-shrink: 0;
    border-radius: 35px;
    background: #F2F0F0;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
    margin-left: auto;
    margin-right: auto;

}

.relais_body .wrap_relais_colis .relais_colis_info{
    color: #000;
    font-family: Poppins;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    display: flex;
    padding-top: 3%;
    /* margin-left: 22%;
    margin-right: 22%; */
    margin-bottom: 22px;

}

.relais_body .wrap_relais_colis .relais_colis_info .n-de-suivi{
    text-align: right;
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.relais_body .wrap_relais_colis .relais_colis_info .n-de-suivi-btnstyle{
    width: 295px;
    height: 50px;
    border-radius: 10px;
    background: #F5C313;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.10);

    text-align: center;
    font-size: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.relais_body .item_style_relais{
    height: auto;

    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    border-radius: 35px;
    background: linear-gradient(88deg, #414141 2.47%, rgba(65, 65, 65, 0.00) 199.63%);
    padding: 21px 21px;
}
.relais_body .item_style_relais .item_class_relai_unit{
    display: flex;
}
.relais_body .item_style_relais .item_class_relai_unit .relais_list_elem .relais_inf_flex_row {
    display: flex;
    gap: 16px;
}
.relais_body .item_style_relais .item_class_relai_unit .relais_list_elem .other_relais_info {
    margin-left: 12%;
}

.relais_body .item_style_relais .item_class_relai_unit .relais_map {
    background-size: 100% 100%;
    height: 602px;
    width: 633px;
}
.relais_body .item_style_relais .item_class_relai_unit .relais_list {
    height: 602px;
    overflow-y: scroll;
    display: block;
}
.relais_body .item_style_relais .item_class_relai_unit .relais_list .relais_list_elem {
    background-color: #fffcef;
    border-radius: 25px;
    height: fit-content;
    /* height: 170px; */
    width: 566px;
    margin: 0px 15px 15px 0px;
    padding: 15px;
}
.leaflet-container {
    width: 100%;
    height: 100%;
    border-radius: 25px;

}
.relais_map .leaflet-container .leaflet-control-attribution a:first-child{
    display: none;
}
.leaflet-container .leaflet-control-attribution {
    margin: 0px 15px 0px 0px;
}
/* CSS list element of relais start */
.relais_body .relais_list_elem .relais_list_elem_group {
    /* height: 123px; */
    height: fit-content;
    min-width: 290px;
}
.relais_body .relais_list_elem .relais_list_elem_overlap-group {
    background-color: #f5c313;
    border-radius: 50%;
    height: 45px;
    min-width: 45px;
}

.relais_body .relais_list_elem .relais_list_elem_overlap-group .relais_list_elem_element {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    transform: translateY(8px);
}

.relais_body .relais_list_elem .relais_list_elem_group .relais_list_elem_name {
    color: #f5c313;
    font-family: "Poppins", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
}
.relais_body .relais_list_elem .relais_list_elem_group .relais_list_elem_addr {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
}

.relais_body .relais_list_elem .relais_list_elem_group .relais_list_elem_tel {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
}
.relais_body .relais_list_elem .relais_list_elem_group .relais_list_elem_ouvert {
    /* color: #006130; */
    color: var(--black);
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: 10px;
}
.relais_body .relais_list_elem .relais_list_elem_group .relais_list_elem_horaire {
    /* color: #006130; */
    color: var(--black);
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: 10px;
    /* text-align: right; */
}
/* .relais_body .relais_list_elem .relais_list_elem_group .relais_list_elem_ouvert .open_close{
    float: left;
    flex: 80%;
} */
.relais_body .relais_list_elem .relais_list_elem_group .relais_list_elem_horaire .horaires{
    float: right;
    padding: 10px;
    margin: -52px 20px 5px 5px;
    border: 0;
    background: transparent;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    text-decoration: underline;
}
.cours__section__form_relais{
    margin: auto;
    position: relative;
    width: 500px; 
}

.section1_search_input_relais {
    -webkit-border-radius: 0 !important;
    border-radius: 50px 0px 0px 50px !important;
    margin: 0;
    width: 80%;
    outline: none;
    padding: 10px 10px;
    display: inline-block;
    font-weight: 500;
    font-size: 17px;
    padding-left: 25px !important; }

.switch_btn_mobile{
    display: none;
    text-align: center;
}

.btn_map{
    display: none;
}

.switch_btn{
    width: 100px;
    height: 35px;
    margin-bottom: 15px;
    border-radius: 20px;
    border: 0;
    background-color: var(--black);
    font-size: 17px;
    color: var(--white-2);
}

.geolocate_me{
    margin-bottom: 15px;
    border: 0;
    text-decoration: underline;
    font-size: 17px;
    color: var(--black);
}
.geolocate_user{
    text-align: center;
}

.custom-marker, .usercustom-marker{
    display: flex;
    transform: translate3d(-8px, -33px, 10px);
}
.custom-marker span{
    transform: translate3d(-22px, 6px, 0px);
    font-weight: bold;
}
.usercustom-marker span{
    transform: translate3d(-25px, 6px, 0px);
    font-weight: bolder;
    font-size: 10px;
}
.modal_horaire {
    flex-direction: column;
    justify-content: center;
    gap: 0.4rem;
    min-width: 350px;
    padding: 1.3rem 4rem;
    min-height: 160px;
  }

  [id^="modal_horaire_hidden_"] {
    display: none; 
  }
  
.horaire_list .horaire_list_el{
    margin: 0 0 8px 0;
    display:flex;
  }
  .horaire_list .horaire_list_el .part1_horaire{
    flex: auto;
    font-weight: bold;
  }
  
  .newbtn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
  }
  .newbtn_open{
    color: green;
  }
  
  /* Sélecteur d'attribut pour changer la couleur en fonction du texte */
  .newbtn_close {
    color: red;
  }
  
  /* .newbtn[data-etat="true "] {
    color: green;
  } */
  
/* End CSS */

@media (max-width: 700px) {

    .modal_horaire {
        min-width: 280px;
        padding: 1.3rem 1.5rem;
        min-height: 160px;
      }
    .switch_btn_mobile{
        display: block;
    }

    .cours__section__form_relais{
        width: 360px;
    }
    .relais_body .relais_title .text-wrapper{
        font-size: 32px;
    }
    .relais_body .vector{
        margin-bottom: 8%;
    }
    .relais_body .wrap_relais_colis .relais_colis_info{
        padding-top: 5%;
        margin-left: 9%;
        margin-right: 9%;
    }
    .relais_body .item_style_relais .item_class_relai_unit{
        display: block;
    }
    .relais_body .item_style_relais .item_class_relai_unit .relais_list .relais_list_elem {
        margin: auto auto 15px auto;
    }

    .relais_body .item_style_relais .item_class_relai_unit .relais_map {
        height: 500px;
        width: 300px;
    }
    .relais_body .item_style_relais .item_class_relai_unit .relais_list .relais_list_elem {
        width: 300px;
    }

    /* .relais_body .item_style_relais .item_class_relai_unit .relais_wrap_map {

    } */
    /* .relais_body .item_style_relais .item_class_relai_unit .relais_list {
        display: none;
    } */
    #relais_list{
        display: none;
    }

    .cours__section__search .cours__section__search__btn_custom {
        width: 44%;
    }

}
