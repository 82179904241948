.gallery {
    --s: 250px; 
    --g: 8px;   
    
    display: grid;
    grid: auto-flow var(--s)/repeat(3,var(--s));
    gap: var(--g);
    background: linear-gradient(88deg, #414141 2.47%, rgba(65, 65, 65, 0.00) 199.63%);
    border-radius: 25px;
  }
  .gallery > img {
    width: 100%; 
    aspect-ratio: 1;
    cursor: pointer;
    z-index: 0;
    transition: .25s,z-index 0s .25s;
  }
  .gallery > img:hover {
    width: calc(200% + var(--g));
    z-index: 1;
    --_c: 80%;
    transition: .4s,z-index 0s;
  }
  .gallery > img:nth-child(1){
    clip-path: circle(var(--_c,73.5% at 52% 74%));
    place-self: start;
  }
  .gallery > img:nth-child(2){
    clip-path: circle(var(--_c,80% at 50% 80%));
    place-self: start auto;
  }
  .gallery > img:nth-child(3){
    clip-path: circle(var(--_c,76% at 50% 75%));
    place-self: auto start;
  }
  .gallery > img:nth-child(4){
    clip-path: circle(var(--_c,73% at 65% 33%));
    place-self: end;
  }
  .gallery > img:nth-child(5){
    clip-path: circle(var(--_c,70% at 50% 25%));
    clip-path: circle(var(--_c,70% at 50% 48%));
    place-self: end;
  }
  .gallery > img:nth-child(6){
    clip-path: circle(var(--_c,75% at 32% 30%));
    place-self: end;
  }
  
  .gallery_container {
    margin: 0;
    display: grid;
    place-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
    overflow: hidden;
    background: #F2F0F0;
  }

  @media (max-width: 700px) {
    .gallery {
        --s: 185px; 
        --g: 8px;   
        
        display: grid;
        grid: auto-flow var(--s)/repeat(2,var(--s));
        gap: var(--g);
      }

      .gallery > img:nth-child(1){
        clip-path: circle(var(--_c,70% at 50% 75%));
      }
      .gallery > img:nth-child(2){
        clip-path: circle(var(--_c,70% at 50% 75%));
        place-self: end;
      }
      .gallery > img:nth-child(3){
        clip-path: circle(var(--_c,70% at 50% 75%));
        clip-path: inset(0);
      }
      .gallery > img:nth-child(4){
        clip-path: inset(0);
        place-self: end;
      }
      .gallery > img:nth-child(5){
        clip-path: circle(var(--_c,70% at 60% 48%));
        place-self: end start;
      }
      .gallery > img:nth-child(6){
        clip-path: circle(var(--_c,75% at 42% 36%));
        place-self: end;
      }

      .gallery > img:hover {
        width: calc(150% + var(--g));
      }

  }