
.error_page
{
  background-color:#141019;
  background: radial-gradient(at 50% -20%, #fff, #0d060e) fixed;
}

#handboy
{
   animation: swing ease-in-out 1.3s infinite alternate;
    transform-origin: 98% 98%;
    transform-box: fill-box;
    
}


#girllight
{
   animation: swing ease-in-out 1.3s infinite alternate;
    transform-origin: 0% 97%;
    transform-box: fill-box;
}

#hairgirl
{
    animation: swinghair ease-in-out 1.3s infinite alternate;
   transform-origin: 60% 0%;
    transform-box: fill-box;
  
}

#zero
{
  transform-origin:bottom;
  transform-box:fill-box;
  
}

/*************swing************/
@keyframes swing {
    0% { transform: rotate(10deg); }
    100% { transform: rotate(-10deg); }
}


/*************swing hair************/
@keyframes swinghair {
    0% { transform: rotate(6deg); }
    100% { transform: rotate(-6deg); }
}