.livraison_groupee_body{
    margin-left: auto;
    margin-right: auto;
    background: #FFF;
    margin-bottom: 10%;
    min-height: 45vh;
}

.livraison_groupee_body .livraison_group_title{
    margin-top: 5%;
}

.livraison_groupee_body .vector{
    margin-bottom: 4%;
}

.livraison_groupee_body .vector_img{
    transform: rotate(-0.174deg);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.livraison_groupee_body .livraison_group_title .text-wrapper{
    color: #F5C313;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.livraison_groupee_body .wrap_ordergroup{
    width: fit-content;
    height: auto;
    flex-shrink: 0;
    border-radius: 35px;
    background: #F2F0F0;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
    margin-left: auto;
    margin-right: auto;

}

.livraison_groupee_body .wrap_ordergroup .livraison_group_info{
    color: #000;
    font-family: Poppins;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    display: flex;
    padding-top: 3%;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 22px;

}

.livraison_groupee_body .wrap_ordergroup .livraison_group_info .n-de-livraison-group{
    text-align: right;
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.livraison_groupee_body .wrap_ordergroup .livraison_group_info .n-de-livraison-group-btnstyle{
    width: 295px;
    height: 50px;
    border-radius: 10px;
    background: #F5C313;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.10);

    text-align: center;
    font-size: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.livraison_groupee_body .all_order_in_group{
    width: fit-content;
    height: auto;
    margin-left: auto;
    margin-right: auto;

    flex-shrink: 0;
    border-radius: 35px;
    background: linear-gradient(70deg, #414141 38.04%, rgba(65, 65, 65, 0.00) 110.04%);
    padding: 21px 21px;
}

.livraison_groupee_body .order_form{
    display: flex;
    margin-bottom: 30px;
}
.livraison_groupee_body .order_form .nice-form-group {
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 10px;
}
.livraison_groupee_body .order_form .nice-form-group label {
    display: block;
    margin-bottom: 0.5em;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.livraison_groupee_body .order_form .nice-form-group input {
    width: 250px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    color: #4E4E4E;

    font-family: Poppins;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    padding: 7px 7px 7px 14px;
}
.livraison_groupee_body .all_order_in_group .nova_logo_img{
    margin-top: -15%;
    transform: translateY(38.5%) translateX(-25%);
}
.livraison_groupee_body  .wrap_ordergroup .carrousel_btns{
    display: none;
}

.carousel_btn_next .arrow-2_elm3 {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
  }

@media (max-width: 700px) {
    .livraison_groupee_body .order_form{
        display: block;
        border-radius: 20px;
        background: radial-gradient(black, transparent);
        margin: 7px;
        padding: 10px;
    }
    .livraison_groupee_body .livraison_group_title .text-wrapper{
        font-size: 32px;
    }
    .livraison_groupee_body .vector{
        margin-bottom: 8%;
    }
    .livraison_groupee_body .wrap_ordergroup .livraison_group_info{
        padding-top: 5%;
        margin-left: 9%;
        margin-right: 9%;
    }

    .livraison_groupee_body .all_order_in_group .nova_logo_img{
        margin-top: -40%;
    }

    /* Carrousel css for mobile */
    .livraison_groupee_body .all_order_in_group .all_order_in_group_carrousel{
        display: flex;
        overflow-x: hidden;
    }
    @keyframes slideaway {
        from { display: block; }
        to { transform: translateX(-100px); opacity: 0;}
      }
    .livraison_groupee_body .all_order_in_group .carousel-item{
        width: fit-content;
        transform: translateX(15px);
    }
    .livraison_groupee_body .all_order_in_group .carousel-item.active{
        display: block;
    }
    .livraison_groupee_body .all_order_in_group .carousel-item.inactive{
        animation: slideaway 200ms;
        display: none;
    }
    .livraison_groupee_body  .wrap_ordergroup .carrousel_btns{
        display: flex;
        transform: translateY(-14vh) translateX(95px);
    }
    .livraison_groupee_body  .wrap_ordergroup .carousel_btn_prev, .livraison_groupee_body .wrap_ordergroup .carousel_btn_next{
        font-size: 1.2em;
        font-weight: 700;
        cursor: pointer;
        border: none;
        background: #fff;
        width: 50px;
        height: 28px;
        padding: 2px 5px 2px 5px;
        border-radius: 15px;
    }
    .livraison_groupee_body  .wrap_ordergroup .carousel_btn_prev{
        margin-right: 10px;
    }
    .livraison_groupee_body .wrap_ordergroup .carousel_btn_next{
        margin-left: 10px;
    }
    .livraison_groupee_body .wrap_ordergroup, .livraison_groupee_body .all_order_in_group {
        width: auto;
    }

}

