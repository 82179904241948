.home_element .section3_home{
    background: #F5C313;
    display: flex;
    padding: 5% 0 5% 0;
}

.home_element .section3_home .section3_home_image {
    height: 586px;
    width: 50vw;
    border-radius: 25px;
    margin-right: 25px;
}
.home_element .section3_home .section3_home_group-7 {
    height: 357px;
    margin: 7%;
}

.home_element .section3_home .achetez-et-fa-tes_home {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 40px;
}

.home_element .section3_home .text-wrapper-11_home {
    color: #252525;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    margin: 5% 40% 0 0;
}

.home_element .section3_home .logos-google-play-wrapper_home {
    background-color: #000000;
    border-radius: 10px;
    height: 70px;
    padding: 2%;
    width: 190px;
    margin-right: 18px;
}

.home_element .section3_home .logos-google-play_home {
    height: 40px;
    width: 174px;
}
.home_element .section3_home .logos-apple-app_home{
    height: 40px;
    width: 40px;
    margin: 7%;
}

.home_element .section3_home .text-wrapper-12_home {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 72px;
    white-space: nowrap;
    margin-left: -4px;
}

.home_element .section3_home  .rectangle-5_home {
    background-color: #000000;
    border-radius: 10px;
    height: 70px;
    width: 190px;
    display: flex;
}

.home_element .section3_home .overlap-4_home {
    height: 72px;
    width: 190px;
}

.home_element .section3_home .logos_home{
    display: flex;
    margin-top: 12%;
    margin-bottom: 15px;
}
.home_element  .section3_home .LOGO-NOVAZON-icone-2_home {
    /* height: 529px; */
    left: 0;
    bottom: 0;
    object-fit: cover;
    position: absolute;
    /* width: 25%; */
    top: 380px;
}

 /* Responsive code  */
 @media (max-width: 700px) {

    .home_element .section3_home{
        display: block;
    }
    .home_element .section3_home .section3_home_image {
        height: 50%;
        width: 95%;
        margin: 0 2.4%;
    }
   
    .home_element .section3_home .achetez-et-fa-tes_home {
        font-size: 32px;
        text-align: center;
    }
    .home_element .section3_home .logos-google-play_home {
        width: 145px;
    }
    .home_element .section3_home .logos-google-play-wrapper_home,
     .home_element .section3_home .overlap-4_home, .home_element .section3_home .rectangle-5_home{
        width: 156px;
        height: 54px;
    }

    .home_element .section3_home .text-wrapper-12_home {
        font-size: 21px;
        font-weight: 444;
        line-height: 54px;
        margin-left: -4px;
    }
    .home_element .section3_home .logos-apple-app_home {
        height: 35px;
        width: 35px;
        margin: 6%;
    }
    .home_element .section3_home .text-wrapper-11_home {
        margin: 20px 0 0 0;
        text-align: center;
    }
    

  }
  @media (max-width: 360px) {
    .home_element .section3_home .achetez-et-fa-tes_home {
        font-size: 30px;}
    
    .home_element .section3_home .logos-google-play-wrapper_home {
            margin-right: 7px;
        }
  }