#moncercle{
      background:#ffffff;
      border-radius:50%;
      width: 50px;
      height: 50px;
      text-align: center;
      padding-top: 5px;
      margin-right: 5px;
    }
.footer_nova .aboutUsfooter{
      display: flex;
}

.footer_nova {
      background: #1b1b1b;
      display: block;
}
.footer_nova .footer_section2 {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, auto));
      gap: 3.5rem;
      width: 90%;
      margin: 0px 6%;
}

.footer_nova .footer-content_nova {
      padding-top: 20px;
}
.footer_nova .first_footer_div {
      padding-left: 40px;
      padding-top: 5px;
}

.footer-content_nova h4 {
      margin-bottom: 1.5rem;
}

.footer-content_nova ul li {
      margin-bottom: 16px;
}

.footer-content_nova ul li a {
      display: block;
      transition: all 0.4s ease;
}

.footer-content_nova ul li a:hover {
      transform: translateY(-3px) translateX(-5px);
      color: var(--white);
}
.footer-content_nova .lorem-ipsum-dolor-si {
      align-self: flex-end;
      color: var(--white);
      font-family: var(--font-family-poppins);
      font-size: var(--font-size-s);
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26.1px;
      margin: 0 0 14px 0;
      max-width: 300px;
}
.footer_nova .icons a {
      display: inline-block;
      transition: all 0.4s ease;
      margin-right: 17px;
}
.footer_nova .icons a:hover {
      transform: translateY(-5px);
}

.footer-content_nova h4.novazon-relay {
      color: var(--white-2);
      font-family: var(--font-family-poppins);
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: normal;
      min-height: 45px;
      min-width: 225px;
}
.footer_nova .setion_two {
    margin-bottom:auto;
}
.footer_nova .footer_section1 .wrap_footer_section1{
      display:flex;
      text-align: center;
      margin: 5% 8% 2% 8%;
}

.footer_nova .footer_section1 .line_footer_nova{
      height: 1px;
      margin-top: 63px;
      object-fit: cover;
      width: 84%;
      margin: 0 8%;
}
.footer_nova .footer_section1 .plus-de-pouvoir-pour_nova{
      color: var(--white-2);
      font-family: var(--font-family-poppins);
      font-size: var(--font-size-xxl);
      font-weight: 600;
      letter-spacing: 0;
      line-height: 46.6px;
      min-height: 100px;
      text-align: left;
}
.footer_nova .footer_section1 .wrap_btns{
      display:flex;
}
.footer_nova .footer_section1 .overlap-6_nova{
      align-items: flex-start;
      background-color: var(--white-2);
      border-radius: 31.76px;
      display: flex;
      height: 62px;
      margin-bottom: 12.11px;
      margin-left: 49px;
      width: 211px;
      padding: 14.1px 28.3px;
}
.footer_nova .footer_section1 .overlap-6_nova .text-wrapper-15_nv{
      color: #1b1b1b;
    font-family: var(--font-family-poppins);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31.3px;
    text-align: center;
    white-space: nowrap;
    width: 153px;

}

.footer_nova .footer_section1 .overlap-7_nova{
      align-items: flex-start;
      background-color: #ffffff2e;
      border-radius: 31.76px;
      display: flex;
      height: 62px;
      margin-bottom: 12.11px;
      margin-left: 22px;
      width: 211px;
      padding: 13.7px 33.3px;
}
.footer_nova .footer_section1 .overlap-7_nova .text-wrapper-16_nv{
      color: var(--white-2);
      font-family: var(--font-family-poppins);
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 31.3px;
      min-height: 31px;
      text-align: center;
      white-space: nowrap;
      width: 144px;
}

.footer_nova .footer_section1{
      padding: 1%;
}
.footer_nova .footer_section3 .copyrigths .madeby{
      text-decoration: underline;
      color: var(--white-2);
}
.footer_nova .footer_section3 .copyrigths {
      text-align: center;
      color: var(--white-2);
      height: fit-content;
      margin-top: 2px;
}


.footer_nova .footer_section3 {
      height: fit-content;
      padding-bottom: 10px;
      margin-top: 15px;
}


@media (max-width: 700px) {
      /* .footer_nova {
            position: relative;
      } */
      .footer_nova .footer-content_nova {
            padding-left: 40px;
      }
      .footer_nova .footer_section1 .wrap_footer_section1{
            display:block;
            text-align: center;
            margin: 5% 8% 2% 8%;
      }
      .footer_nova .footer_section1 .overlap-6_nova {
            border-radius: 31.76px;
            height: 50px;
            margin-top: 30px;
            margin-bottom: 12px;
            width: 150px;
            padding: 10px 10px;
            margin-left: 5px;
        }
        .footer_nova .footer_section1 .overlap-7_nova {
            align-items: flex-start;
            background-color: #ffffff2e;
            border-radius: 31.76px;
            display: flex;
            height: 50px;
            margin-top: 30px;
            margin-bottom: 12px;
            margin-left: 20px;
            width: 150px;
            padding: 10px 10px;
        }
        .footer_nova .footer_section2 {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0.5rem;
        }
        .footer_nova .footer_section3 {
            margin: 15px 22px;
        }
}

@media (max-width: 360px) {

      .footer_nova .footer_section1 .overlap-6_nova {
            width: 130px;
        }
        .footer_nova .footer_section1 .overlap-7_nova {
            width: 130px;
        }

        .footer_nova .footer_section1 .overlap-6_nova .text-wrapper-15_nv{
          font-size: 17px;
      }
      .footer_nova .footer_section1 .overlap-7_nova .text-wrapper-16_nv{
            font-size: 17px;
      }
      .footer_nova .footer-content_nova {
            padding-left: 25px;
        }

}