.d_none{
    display: none;
}
.home_element .section0_home{
    background: #FFF;
    background-image: url("../../assets/rectangle-3.svg");
    display: flex;
}
.home_element .section0_home .left_part_section0_home,.home_element .section0_home .right_part_section0_home{
    width: 50%;
}
.home_element .section0_home .left_part_section0_home{
    padding: 12% 0% 0% 12%;
}

.section0_home .left_part_section0_home .section0_home_title{
    color: var(--black);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 700;

    letter-spacing: 0;
    line-height: 40px;
}

.section0_home .left_part_section0_home .section0_home_description{
    color: var(--abbey);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-s);
    font-weight: 300;
    letter-spacing: 0;
    line-height: normal;
    margin-top: 25px;
    margin-bottom: 25px;
}
.section0_home .left_part_section0_home .section0_home_video_btn {
    display: flex;
}
.section0_home .left_part_section0_home .section0_home_video_btn .text-wrapper_section0{
    color: var(--black);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-s);
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    padding: 12px;
}

.section0_home .left_part_section0_home .section0_home_video_btn .wrap_ellipse_and_polygon{
    border: 1px solid;
    border-color: var(--lightning-yellow);
    border-radius: 20px;
    height: 40px;
    width: 40px;
    text-align: center;
    padding: 12px 15px
}
.section0_home .left_part_section0_home .LOGO-NOVAZON-icone_section0_home{
    transform: translate(-146%, 1%);
    object-fit: none;
    object-position: 0% 45%;
    height: 240px;
}

@media (max-width: 1200px) {
    .section0_home .left_part_section0_home .LOGO-NOVAZON-icone_section0_home{
        transform: translate(-120%, 1%);
    }
}
@media (max-width: 950px) {
    .section0_home .left_part_section0_home .LOGO-NOVAZON-icone_section0_home{
        transform: translate(-100%, 1%);
        height: 230px;
    }
}
@media (max-width: 700px) {

    .section0_home .left_part_section0_home .LOGO-NOVAZON-icone_section0_home{
        transform: translate(-17%, 40%);
        height: 230px;
    }
    .home_element .section0_home {
        display: block;
        height: 505px;
    }
   
    .home_element .section0_home .left_part_section0_home {
        padding: 5% 5% 0% 5%;
    }
    .home_element .section0_home .left_part_section0_home, .home_element .section0_home .right_part_section0_home {
        width: 100%;
    }
    .home_element .section0_home .right_part_section0_home {
        display: none;
    }
    .section0_home .left_part_section0_home .delivery-address_section0_displaynone{
        display: block;
        height: 290px;
        width: 300px;
        transform: translate(29%, -75%);
    }

    .section0_home .left_part_section0_home .section0_home_description{
        display: none;
    }
    
    .section0_home .left_part_section0_home .section0_home_video_btn{
        display: none;
    }

    .section0_home .left_part_section0_home .section0_home_title {
    text-align: center;
    font-size: 30px;

}
.home_element .section0_home{
    background-image: url("../../assets/rectangle-3_mobile.svg");
    background-repeat: no-repeat;
    background-position: center;
}

}