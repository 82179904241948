.section4_home{
    margin-top: 20px;
}
.section4_home .section4_home_body1{
    display: flex;
    width: 100vw;
}

.section4_home .section4_home_body1 .ce-qu-ils-disent-wrapper_home{
    background-color: #f5c313;
    border-radius: 0px 54.5px 54.5px 0px;
    height: 109px;
    width: 481px;
    text-align: center;
    padding: 18px;
}
.section4_home .section4_home_body1 .ce-qu-ils-disent-wrapper_home .ce-qu-ils-disent_home{
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 73.8px;
    white-space: nowrap;
}
.section4_home .section4_home_body1 .bxs-quote-alt-left_home_wrap{
    width: 50%;
    text-align: end;
    transform: translate(20%,5%);
}

.section4_home .section4_home_body2{
    padding: 10px;
    margin: 22px 10%;
}

.section4_home_body2 .j-adore-la-simplicit_home {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 60.5px;
}

.section4_home .section4_home_body3{
    display: flex;
    text-align: center;
    width: 100vw;
    margin: 22px 9.5%;
}
.section4_home .section4_home_body3 .section4_home_body3_elm1{
    height: 80px;
    width: 80px;
}

.section4_home .section4_home_body3 .section4_home_body3_elm2{
    text-align: left;
    padding: 15px;
}

.section4_home .section4_home_body3 .section4_home_body3_elm2 .text-wrapper-13_elm2{
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26.9px;
    white-space: nowrap;
}
.section4_home .section4_home_body3 .section4_home_body3_elm2 .text-wrapper-14_elm2{
    color: #7b7b7b;
    font-family: "Poppins", Helvetica;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20.2px;
    white-space: nowrap;
}
.section4_home .section4_home_body3 .section4_home_body3_elm3{
    width: 50%;
    text-align: end;
    transform: translateX(28%);
}
.section4_home .section4_home_body3 .section4_home_body3_elm3 .arrow_elm3{
    margin: 25px;
}
.section4_home .section4_home_body3 .section4_home_body3_elm3 .arrow-2_elm3{
    margin: 25px;
}

 /* Responsive code  */
 @media (max-width: 700px) {

    .section4_home .section4_home_body1 .ce-qu-ils-disent-wrapper_home {
        height: 109px;
        width: 350px;
        padding: 10px;
    }
    .section4_home .section4_home_body1 .ce-qu-ils-disent-wrapper_home .ce-qu-ils-disent_home {
        font-size: 32px;
    }
    .section4_home .section4_home_body1 .bxs-quote-alt-left_home_wrap {
        width: 50%;
        text-align: end;
        transform: translate(1%,25%);
    }
    .section4_home_body2 .j-adore-la-simplicit_home {
        font-size: 30px;
        line-height: 32px;
    }
    .section4_home .section4_home_body3 .section4_home_body3_elm3 {
        transform: translate(-35%,60%);
    }
    .section4_home .section4_home_body3 .section4_home_body3_elm3 .arrow-2_elm3 {
        margin: 14px;
    }
    .section4_home .section4_home_body3 .section4_home_body3_elm3 .arrow_elm3 {
        margin: 14px;
    }

 }
